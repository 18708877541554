<template>
  <div class="index">
    <div class="logo">
        <img src="../../../assets/LOGO.svg" alt="">
    </div>
    <h1>{{ product | breakFilter}}</h1>
    <h2>Quick Start Guide</h2>
    <p class="tips mar-t-50">For a better browsing experience, </p>
    <p class="tips">please visit the link on computers:</p>
    <p style="color: #c7000b;">{{ fullPath }}</p>
    <p class="p1">Please select the language of the document</p>
    <ul>
        <li v-for="(item, index) in langDocs" :key="index">
            <div class="btn" v-if="item.language === 'en'" @click="docShow(item.docUrl)">English</div>
            <div class="btn" v-if="item.language === 'fr'" @click="docShow(item.docUrl)">Français</div>
            <div class="btn" v-if="item.language === 'es'" @click="docShow(item.docUrl)">Español</div>
            <div class="btn" v-if="item.language === 'pe'" @click="docShow(item.docUrl)">Português (Europeu)</div>
            <div class="btn" v-if="item.language === 'pb'" @click="docShow(item.docUrl)">Português (Brasil)</div>
            <div class="btn" v-if="item.language === 'th'" @click="docShow(item.docUrl)">ภาษาไทย</div>
            <div class="btn" v-if="item.language === 'ita'" @click="docShow(item.docUrl)">Italiano</div>
            <div class="btn" v-if="item.language === 'tur'" @click="docShow(item.docUrl)">Türkçe</div>
            <div class="btn" v-if="item.language === 'ar'" @click="docShow(item.docUrl)">اللغة العربية</div>
            <div class="btn" v-if="item.language === 'ch'" @click="docShow(item.docUrl)">中文</div>
            <!-- https://resources.holowits.com.sg/instructions_icon/lightning-protection.svg -->
            <!-- https://resources.holowits.com.sg/instructions_icon/water-proof-insulation.svg -->
        </li>
    </ul>
    <ul>
        <li style="font-size: 26px;">Quick access to common functions</li>
        <li>
            <div class="btn_line" @click="docShow('https://www.holowits.com/htmldoc/1820261247434620928')"><img class="lp" src="https://resources.holowits.com.sg/instructions_icon/lightning-protection.svg"/>Lightning Protection and Earthing</div>
        </li>
        <li>
            <div class="btn_line" @click="docShow('https://www.holowits.com/htmldoc/1820269435219345408')"><img class="wp" src="https://resources.holowits.com.sg/instructions_icon/water-proof-insulation.svg"/>Waterproofing and Insulation</div>
        </li>
    </ul>
  </div>
</template>

<script>
import {getDocsByProduct} from '@/api/product';
export default {
    name: 'Instructions',
    data() {
        return {
            product: '',
            langDocs: [
                {
                    language: 'en',
                    docUrl: ''
                }, 
                {
                    language: 'fr',
                    docUrl: ''
                }, 
                {
                    language: 'es',
                    docUrl: ''
                }, 
                {
                    language: 'pe',
                    docUrl: ''
                }, 
                {
                    language: 'pb',
                    docUrl: ''
                }, 
                {
                    language: 'th',
                    docUrl: ''
                }, 
                {
                    language: 'lta',
                    docUrl: ''
                }, 
                {
                    language: 'tur',
                    docUrl: ''
                }, 
                {
                    language: 'ch',
                    docUrl: ''
                },
                {
                    language: 'ar',
                    docUrl: ''
                },
                {
                    language: 'lp',
                    docUrl: ''
                },
                {
                    language: 'wp',
                    docUrl: ''
                },
            ],
            fullPath: '',
        }
    },
    mounted() {
        this.product = this.$route.params.product;
        this.fullPath = `https://www.holowits.com${this.$route.fullPath}`;
        this.getData()
    },
    methods: {
        // 根据产品名获取所有相关语言文档
        docShow(path) {
            window.open(path, '_blank');
        },
        async getData() {
            const {data: {data, isSuccess}} = await getDocsByProduct({product: this.product});
            if (isSuccess) {
                this.langDocs = data;
            }
        },
    },
    filters: {
        breakFilter(value) {
            return value.replace('(', '\n(');
        }
    }
}
</script>

<style lang="less" scoped>
.index {
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 100px;
    .logo {
        padding-top: 100px;
        padding-bottom: 80px;
        img {
            height: 100px;
            max-width: 393px;
        }
    }
    h1 {
        text-align: center;
        font-size: 55px;
        font-weight: bold;
        white-space: pre-line;
    }
    h2 {
        margin-top: 40px;
        text-align: center;
        font-size: 54px;
    }
    p {
        text-align: center;
    }
    .tips {
        font-size: 16px;
    }
    .mar-t-50 {
        margin-top: 50px;
    }
    .p1 {
        margin-top: 50px;
        font-size: 30px;
    }
    ul {
        padding-bottom: 30px;
    }
    li {
        text-align: center;
        margin-top: 20px;
    }
    .btn {
        width: 300px;
        margin: 0 auto;
        background-color: #c7000b;
        font-size: 20px;
        padding: 10px 20px;
        color: #fff;
    }
    .btn_line {
        width: 420px;
        margin: 0 auto;
        background-color: #fff3f3;
        font-size: 20px;
        padding: 10px 20px;
        color: #c7000a;
        border: 1px solid #c7000a;
        border-radius: 4px;
        cursor: pointer;

        .lp {
            width: 2.25rem;
            height: 2.25rem;
            margin-right: 10px;
        }
        .wp {
            width: 2.25rem;
            height: 2.25rem;
            margin-right: 10px;
        }
    }
}
@media screen and (max-width: 991px) {
    .index {
        max-width: 480px;
        margin: 0 auto;
        .logo {
            padding-top: 20px;
            padding-bottom: 80px;
            img {
                height: 60px;
                max-width: 250px;
            }
        }
        h1 {
            font-size: 30px;
            // word-wrap: break-word;
        }
        h2 {
            margin-top: 40px;
            text-align: center;
            font-size: 25px;
        }
        .p1 {
            margin-top: 40px;
            font-size: 24px;
        }
        .btn {
            width: 180px;
            font-size: 16px;
        }
    }
}
</style>