import axiosApi from '@/api/axiosApi';

//分页商品详情
export const getProductDetail = (data) => axiosApi({
  method: 'POST',
  url: '/product/product/anno/detail',
  data,
});

//获取筛选项
export const getFilterItem = (data) => axiosApi({
  method: 'GET',
  url: '/product/filter/anno/getFilterItem',
  data,
});

//获取商品类型
export const getProductTypeFilter = (data) => axiosApi({
  method: 'GET',
  url: '/product/filter/anno/getProductTypeFilter',
  data,
});

//按条件查询产品列表
export const getProductList = (data) => axiosApi({
  method: 'POST',
  url: '/product/product/anno/filterProduct',
  data,
});

//根据产品name查询对应的Doc
export const getDocByProductName = (data) => axiosApi({
  method: 'POST',
  url: `/product/product/anno/getDocByProductName`,
  data
});

export const getDocsByProduct = (data) => axiosApi({
  method: 'POST',
  url: `/product/product/anno/getDocsByProduct`,
  data
})

// 按条件搜索全部产品
export const getAllProduct = (data) => axiosApi({
  method: 'POST',
  url: `/product/product/anno/getAllProduct`,
  data
})

export const getSpecDetailsByProductId = (data) => axiosApi({
  method: 'POST',
  url: `/product/specification-template/getSpecDetailsByProductId`,
  data
})

// 产品对比
export const comparison = (data) => axiosApi({
  method: 'POST',
  url: `/product/product/anno/comparison`,
  data
})

export const getTempByType = (data) => axiosApi({
  method: 'POST',
  url: `/product/specification/anno/getTempByType`,
  data
})
